import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

import './scoped-style/BlogRoll.scss'
import { Const } from '../const/const'

class BlogRoll extends React.Component {
  
  constructor() {
    super();
    this.state = {
      tag: '',
      selected: Const.TAG_LIST,
    }
  }

  clickTag(item) {
    const setValue = item === this.state.tag ? '' : item; 
    this.setState({
      tag: setValue
    })
  }
  
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    
    return (
      <div>
        <div className="tags-area">
          Tags：
          {this.state.selected.map(( item, index) => (
            <button key={index} 
              className={"button is-rounded is-small" + (item === this.state.tag ? ' selected': '')} 
              onClick={this.clickTag.bind(this, item)}
            >{item}</button>
          ))}
        </div>
        <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}
              style={{
                display: this.state.tag === '' || post.frontmatter.tags.includes(this.state.tag) ? 'block' : 'none'
              }}
            > 
              <Link to={post.fields.slug}>
                <article
                  className={`blog-list-item tile is-child box notification ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                >
                  <header>
                    {post.frontmatter.featuredimage ? (
                      <div className="featured-thumbnail">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      </div>
                    ) : null}
                    <p className="post-meta">
                      <span className="is-size-5 has-text-weight-bold">{post.frontmatter.title}</span>
                      <span className="is-block">
                        {post.frontmatter.date}
                      </span>
                    </p>
                  </header>
                  <p className="is-size-excerpt is-hidden-mobile">
                    {post.excerpt}
                    <br />
                    <br />
                  </p>
                </article>
              </Link>
            </div>
          ))}
        </div>
      </div>
      
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" }, featuredpost: {eq: true} } }
        ) {
          edges {
            node {
              excerpt(truncate: true, pruneLength: 110)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "YYYY年 M月 D日")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 75) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
                description
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
