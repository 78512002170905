import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'

import './scoped-style/index-page.scss'
import BlogRoll from '../components/BlogRoll'
import SideSection from '../components/SideSection'
import topYogaImg from '../img/topYoga.jpg'
import logo from '../img/HatchfulExport-All/logo_transparent.png'
import logoOnly from '../img/HatchfulExport-All/hero_logo.png'
import topImg from '../img/top_m.jpg'

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="index-page-template">
          <div>
            <div
              className="full-width-image margin-top-0 hero"
            >
              <img className="hero-image" src={topImg} alt="top-yoga-image"></img>
              <div className="wrap-title">
                <div className="hero-title">
                  <img className="logo" src={logoOnly} alt="yuruyoga"></img>
                </div>
                <div className="hero-description">
                  ココロとカラダを自由に<br />〜由琉YOGA〜
                </div>
              </div>
            </div>
          </div>
          <div className="catchphrase">
            <div className="wrap-description top-sentence">
              <h2>名古屋市港区ママヨガサークルなら</h2>
              <h2>ゆるヨガサークル</h2>
            </div>
          </div>
          <div className="top-image">
            <div className="wrap-image">
              <img src={topYogaImg} alt="lesson room image"></img>
            </div>
          </div>
          <div className="catchphrase">
            <img src={logo} alt="yuruyoga logo" />
            <h1>ENJOY THE MOMENT</h1>
            <div className="wrap-description">
              <h2>「ココロとカラダを自由にし、今を楽しんでほしい」</h2>
              <h2>そんな想いから「ゆるヨガサークル」が誕生しました</h2>
              <h2>多忙な日々、今この瞬間を味わうことを忘れがちです</h2>
              <h2>育児や仕事などの疲れやストレスから離れ</h2>
              <h2>今ここで一緒にヨガを楽しみませんか？</h2>
            </div>
            <Link className="to-about" to="/about">
              <button className="button is-outlined">詳しくはこちら</button>
            </Link>
          </div>
          <div className="wrap-section">
            <section className="section section--gradient">
              <div className="container">
                <div className="">
                  <div className="columns">
                    <div className="column is-10 is-offset-1">
                      <div className="content">
                        <div style={{ display: 'none' }} className="is-12">
                          <h3 className="has-text-weight-semibold is-size-4">
                            最新の記事
                          </h3>
                          <BlogRoll />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}
